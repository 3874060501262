// @ts-nocheck
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { logist, product1, ship1, ship2 } from "../assets/img";
import RequiredSummary from "../component/Order/RequiredSummary";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateQuantity from "../component/MyProductDetail/UpdateQuantity";
import YourStorageComp from "../component/Order/YourStorageComp";
import ShipmentPopup from "../component/Shipments/ShipmentPopup";
import { useGetOrderDataMutation } from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const ShippmentScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  console.log(location.state, "state");

  const navigate = useNavigate();
  const [OpenSummery, setOpenSummery] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [storagePopup, setStoragePopup] = useState(false);
  const [shipPopup, setShipPopup] = useState(false);
  const [list, setList] = useState({});
  const [breakdownList, setBreakdownList] = useState({});

  const [orderData] = useGetOrderDataMutation();

  const createOrder = () => {
    dispatch(saveLoader(true));
    const formdata = new FormData();

    formdata.append("project_id", location.state?.id);

    orderData(formdata)
      .unwrap()
      .then((res) => {
        // console.log(res, "  ");
        setList(res?.data);

        dispatch(saveLoader(false));
        // navigate("/my-order");
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log(err);
      });
  };
  useEffect(() => {
    if (location.state?.id) {
      createOrder();
    }
  }, []);
  const openSummeryHandler = () => {
    setOpenSummery(!OpenSummery);
    setShowUpdate(false);
  };
  const showClickHandler = () => {
    setShowUpdate(false);
    // setStoragePopup(!storagePopup);
    setStoragePopup(false);
    setShipPopup(false);
  };

  const viewBreakdown = (item) => {
    setBreakdownList(item);
    setShowUpdate(true);
  };

  return (
    <div className="tl-shipment-screen">
      {storagePopup && (
        <YourStorageComp
          showUpdate={storagePopup}
          showClickHandler={showClickHandler}
        />
      )}
      {shipPopup && (
        <ShipmentPopup
          showUpdate={shipPopup}
          showClickHandler={showClickHandler}
        />
      )}
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2">
        <button
          className="parag bg-transparent border-0"
          onClick={() => {
            navigate("/my-order");
          }}
        >
          <ArrowBackIosIcon /> Back to Orders
        </button>
        <h4 className="mb-0 mt-5 f4 fs-25 fs-sm-30 fs-lg-35 fs-xl-40">
          Products
        </h4>
        <div className="d-flex flex-wrap gap-4 align-items-start p-2 my-2 product-list-detail">
          {list?.products?.map((item, ind) => {
            return (
              <div className="d-flex box-shadow-product_new my-3" key={ind}>
                <div className="img-box-tee_new me-2">
                  <img
                    src={item?.image ? item?.image : product1}
                    className="w-100"
                  />
                </div>
                <div className=" px-3">
                  <p class="bg-approved lt-approved fs-11 fs-md-12 f2 rounded rounded-2  d-initial py-1 px-2 mb-3">
                    Approved
                  </p>
                  <h3 className="cust-btn mt-3 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                    {item?.name}
                  </h3>
                  <div className="d-flex align-items-center">
                    <img className="logists" src={logist} alt="" />
                    <p className="parag mb-0 ms-2 fs-xl-16">
                      {" "}
                      {`${item?.qty}qty`}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    {/* < className="logists" src={logist} alt="" /> */}
                    <p className="parag mb-0 ms-2 fs-xl-16">
                      price: ₹{item?.price}{" "}
                    </p>
                  </div>
                  {list?.tax_type == 1 && (
                    <div className="d-flex align-items-center">
                      {/* <img className="logists" src={logist} alt="" /> */}
                      <p className="parag mb-0 ms-2 fs-xl-16">
                        Tax: {item?.tax_amount} tax
                      </p>
                    </div>
                  )}
                  <button
                    onClick={() =>
                      navigate("/my-product-View_Proof_detail", {
                        state: { project: list, type: "order" },
                      })
                    }
                    className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary mt-2"
                  >
                    View Proof
                    <ArrowForwardIosIcon className="bg-transparent border-0 f4 fs-12 fs-sm-14  fs-lg-16" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="product-shipment d-flex flex-column flex-lg-row justify-content-between mt-3">
          <div className="">
            <h4 className="mb-0 f4 fs-25 fs-sm-30 fs-lg-35 fs-xl-40">
              Products
            </h4>
            <div className="d-flex mt-3 mt-lg-4">
              <div className="prod-list-box-tl1">
                <div className="prod-list-boxss">
                  <img src={product1} alt="" />
                </div>
              </div>
              <div className=" px-3">
                <p class="bg-approved lt-approved fs-11 fs-md-12 f2 rounded rounded-2  d-initial py-1 px-2 mb-3">
                  Approved
                </p>
                <h3 className="cust-btn mt-3 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                  Product 1
                </h3>
                <div className="d-flex align-items-center">
                  <img className="logists" src={logist} alt="" />
                  <p className="parag mb-0 ms-2 fs-xl-16"> 25 Items</p>
                </div>
                <button
                  onClick={() => navigate("/my-product-detail")}
                  className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary mt-2"
                >
                  View Proof
                  <ArrowForwardIosIcon className="bg-transparent border-0 f4 fs-12 fs-sm-14  fs-lg-16" />
                </button>
              </div>
            </div>
          </div>
          <div className="ship-stor">
            <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
              Shipping & Storage
            </h6>
            <div className="d-flex flex-column p-4 mt-3 mt-lg-3 inner-ship-stor position-relative">
              <div className="ship-recipt d-flex ac-jb mb-2 w-100">
                <h3 className="cust-btn mb-0 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark w-50">
                  Ship to Recipients
                </h3>
                <button
                  onClick={() => setShipPopup(!shipPopup)}
                  className="bg-transparent border-0 f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 primary w-50 d-flex justify-content-end align-items-center"
                >
                  View Shipments
                  <ArrowForwardIosIcon className="bg-transparent border-0 f4 fs-12 fs-sm-14  fs-lg-16 ms-2" />
                </button>
              </div>
              <div className="ship-recipt d-flex mb-0 w-100">
                <div className="w-50">
                  <h3 className="cust-btn primary1 mt-3 f2 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1">
                    Shipment Qty
                  </h3>
                  <h3 className="cust-btn primary1 mt-0 f4 fs-15 fs-md-16 fs-lg-22 fs-xl-24 fs-xxl-25 text-dark">
                    100
                  </h3>
                </div>
                <div className="w-50">
                  <h3 className="cust-btn primary1 mt-3 f2 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1">
                    Shipment Cost
                  </h3>
                  <h3 className="cust-btn primary1 mt-0 f4 fs-15 fs-md-16 fs-lg-22 fs-xl-24 fs-xxl-25 text-dark">
                    $0.00
                  </h3>
                </div>
              </div>
              <button
                onClick={() => navigate("/select-product")}
                className="get-start-btn border-0 rounded mt-4 mb-3"
              >
                Create Shipment
              </button>
              <div className="ship-img">
                <img src={ship1} alt="" className={ship1} />
              </div>
            </div>
            <div className="d-flex flex-column p-4 inner-ship-stor position-relative">
              <div className="ship-recipt d-flex ac-jb mb-2 w-100">
                <h3 className="cust-btn mb-0 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark w-50">
                  Store in Warehouse
                </h3>
              </div>
              <div className="ship-recipt d-flex mb-0 w-100">
                <div className="w-50">
                  <h3 className="cust-btn primary1 mt-3 f2 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1">
                    Store Qty
                  </h3>
                  <h3 className="cust-btn primary1 mt-0 f4 fs-15 fs-md-16 fs-lg-22 fs-xl-24 fs-xxl-25 text-dark">
                    100
                  </h3>
                </div>
                <div className="w-50">
                  <h3 className="cust-btn primary1 mt-3 f2 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1">
                    Storage Cost
                  </h3>
                  <h3 className="cust-btn primary1 mt-0 f4 fs-15 fs-md-16 fs-lg-22 fs-xl-24 fs-xxl-25 text-dark">
                    $0.00
                  </h3>
                </div>
              </div>
              <button
                onClick={() => setStoragePopup(!storagePopup)}
                className="get-start-btn border-0 rounded mt-4 mb-3"
              >
                View Storage
              </button>
              <div className="ship-img">
                <img src={ship2} alt="" className={ship2} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <RequiredSummary
        showClickHandler={showClickHandler}
        OpenSummery={OpenSummery}
        showUpdate={setShowUpdate}
        openSummeryHandler={openSummeryHandler}
        list={list}
        viewBreakdown={viewBreakdown}
      />
      <UpdateQuantity
        showUpdate={showUpdate}
        showUpdateClick={setShowUpdate}
        showClickHandler={showClickHandler}
        breakdownList={breakdownList}
      />
    </div>
  );
};

export default ShippmentScreen;

// @ts-nocheck
import React, { useEffect, useState } from "react";
import LeftProduct from "../component/Product/Bulk/LeftProduct";
import ProductPopupComponent from "../component/Product/Bulk/ProductPopupComponent";
import RightProducts from "../component/Product/Bulk/RightProducts";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import BulkProductCart from "../component/Product/BulkProductCart";
import {
  useAddToCartMutation,
  useCartListMutation,
  useCategoryListMutation,
  useDeleteCartMutation,
  usePackUpdateApiMutation,
  useProductListMutation,
  useProductPriceListMutation,
  useProductViewMutation,
  useUpdateToCartMutation,
} from "../redux/api/api";
import BulkProductFullScreen from "./BulkProductFullScreen";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const BulkProductScreen = () => {
  const dispatch = useDispatch();
  const Token = localStorage.getItem("token");
  const [dataBase, setDataBase] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [tick, setTick] = useState([]);
  const [popData, setPopData] = useState();
  //Category
  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [viewId, setViewId] = useState("");
  const [viewList, setViewList] = useState([]);
  const [imagesid, setImagesId] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [packsPrice, setPacksPrice] = useState("");
  const [count, setCount] = useState(1);
  const [colorcount, setColorCount] = useState(1);
  const [cartList, setCartList] = useState([]);
  const [priceList, setPriceList] = useState();
  const [bigImg, setBigImg] = useState(0);
  const [defaultimg, setDefault] = useState("");
  const [style, setStyle] = useState("");
  const [size, setSize] = useState("");
  const [material, setMaterial] = useState("");
  const [selectedColorId, setSelectedColorId] = useState("");
  const [selectColorId, setSelectColorId] = useState("");
  const [skuCodeList, setCodeList] = useState([]);
  const [skuCode, setCode] = useState("");
  const [pack, setPack] = useState(100);
  const [totalPrice, setTotalPrice] = useState("");
  const [totalPerPrice, setTotalPerPrice] = useState("");
  const [cartData, setCartData] = useState({});
  const [userCartID, setUserCartID] = useState("");
  const [ListPrice, setListPrice] = useState({});
  const [cartBtn, setCartBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [showoption, setShowOption] = useState(null);
  const [noOfPack, setNoOfPack] = useState("");
  const [noOfPackErr, setNoOfPackErr] = useState(false);
  const [normalid, setNormalId] = useState([]);
  const [values, setValues] = useState([5, 1000]);
  const [priceSort, SetPriceSort] = useState("");

  //RTK
  const [ProductListApi] = useProductListMutation();
  const [productViewApi] = useProductViewMutation();
  const [productPriceApi] = useProductPriceListMutation();
  const [addToCartApi] = useAddToCartMutation();
  const [cartListApi] = useCartListMutation();
  const [editToCartApi] = useUpdateToCartMutation();
  const [deleteApi] = useDeleteCartMutation();
  const [packUpdateApi] = usePackUpdateApiMutation();

  const product = (colorId, event) => {
    const formData = new FormData();
    if (colorId) {
      formData.append("color_id", colorId);
    }
    if (event) {
      formData.append("search", event);
    }
    formData.append("start_price", values[0]);
    formData.append("end_price", values[1]);
    if (normalid?.length > 0) {
      normalid?.map((item, ind) => {
        formData.append(`tag_id[${ind}]`, item);
      });
    }
    priceSort == "high"
      ? formData.append("high_to_low", 1)
      : formData.append("low_to_high", 1);

    ProductListApi(formData)
      .unwrap()
      .then((res) => {
        let temp = [];
        let skuTemp = [];
        res?.navigations.map((item) => {
          item?.products.map((product) => {
            temp.push(product);
          });
        });

        const ids = temp.map(({ id }) => id);
        const filtered = temp.filter(
          ({ id }, index) => !ids.includes(id, index + 1)
        );

        filtered?.map((item, ind) => {
          item?.productSku?.map((sku) => {
            skuTemp.push(sku);
          });
        });
        setProductList(filtered);
        setSkuList(skuTemp);
        setCategoryList(res?.navigations);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    product();
  }, [values, normalid, priceSort]);

  // DELETE CART
  const deleteCart = (id, pro_id) => {
    if (!Token) {
      deleteLocalCart(pro_id);
    } else {
      const formData = new FormData();
      formData.append("user_cart_detail_id", id);
      // console.log("pro_id", pro_id);
      dispatch(saveLoader(true));
      setDeleteBtn(true);
      deleteApi(formData)
        .unwrap()
        .then((res) => {
          // console.log(res?.navigations);
          // setCategoryList(res?.navigations);

          dispatch(saveLoader(false));
          setDeleteBtn(false);
          let obj = { ...cartData };
          delete obj[pro_id];
          setCartData(obj);
          getCartApi();
          let temp = [...tick];
          let inx = temp.indexOf(pro_id);
          temp.splice(inx, 1);
          setTick(temp);
          setShowPopup(false);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setDeleteBtn(false);
          console.log("err", err);
        });
    }
  };

  // ADD TO CART
  const varients = (
    id,
    qty,
    packsPrice,
    colors_name,
    styles_name,
    materials_name,
    sizes_name,
    skuCodes,
    type,
    method
  ) => {
    let codeFinder = skuCodeList?.find((i) => i?.color_id == selectColorId);
    // console.log("codeFinder", codeFinder);
    // setCode(skuCode)
    if (codeFinder?.product_parent_sku?.id) {
      if (Token) {
        getAddToCart(codeFinder?.product_parent_sku?.id, id, qty, packsPrice);
      } else {
        addLocalCart(
          codeFinder?.product_parent_sku?.id,
          id,
          qty,
          packsPrice,
          type,
          method
        );
      }
    }
  };

  // CART LIST
  const getCartApi = () => {
    const formData = new FormData();
    formData.append("type", 3);
    dispatch(saveLoader(true));
    cartListApi(formData)
      .unwrap()
      .then((res) => {
        const data = res?.cart_detail?.cartDetail;
        const temp = [];
        let tempArr = [];
        data?.map((item) => {
          temp?.push(item?.product?.id);
          setTick(temp);
        });

        if (res?.cart_detail?.id) {
          let qtys = res?.cart_detail?.no_of_packs;
          let total_price_cart;
          res?.cart_detail?.cartDetail?.map((item, ind) => {
            let qty_val = item?.product?.[`for_${qtys}`];
            let sum = +item?.qty * qtys;
            let obj = {
              for_price: +qty_val,
              pack: res?.cart_detail?.no_of_packs,
              count: +item?.qty,
              cart_id: item?.id,
              no_of_colors: item?.no_of_colors,
            };

            if (qty_val) {
              obj.for_price = +qty_val;
            } else {
              obj.for_price = +qtys;
            }

            if (+sum >= 1 && +sum <= 25) {
              obj.packs_price = +item?.product?.for_25;
              total_price_cart = +item?.product?.for_25;
            } else if (+sum > 25 && +sum <= 50) {
              obj.packs_price = +item?.product?.for_50;
              total_price_cart = +item?.product?.for_50;
            } else if (+sum > 50 && +sum <= 100) {
              obj.packs_price = +item?.product?.for_100;
              total_price_cart = +item?.product?.for_100;
            } else if (+sum > 100 && +sum <= 150) {
              obj.packs_price = +item?.product?.for_150;
              total_price_cart = +item?.product?.for_150;
            } else if (+sum > 150 && +sum <= 250) {
              obj.packs_price = +item?.product?.for_250;
              total_price_cart = +item?.product?.for_250;
            } else if (+sum > 250 && +sum <= 500) {
              obj.packs_price = +item?.product?.for_500;
              total_price_cart = +item?.product?.for_500;
            } else if (+sum > 500 && +sum <= 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            } else if (+sum > 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            }

            tempArr.push({
              ...item,
              total_price: total_price_cart,
            });

            setCartData((val) => ({
              ...val,
              [item?.product?.id]: obj,
            }));
          });
          setPack(res?.cart_detail?.no_of_packs);
          setTotalPrice(res?.cart_detail?.total_amount);
          setTotalPerPrice(res?.cart_detail?.price_per_pack);
          setCartList(tempArr);
          setPriceList(res?.pack_prices);
        } else {
          setCartList([]);
          setPriceList({});
        }
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log(err);
      });
  };

  // PRODUCT VIEW
  const getProductView = (id, cartID, item) => {
    const formData = new FormData();
    setViewId(id);
    formData?.append("product_ids", id);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: id })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };
        // let colorFinder = res?.product?.colors?.find(
        //   (i) => i?.id == item?.product_sku?.color_id
        // );
        // let findIndex = res?.product?.colors?.findIndex(
        //   (i) => i?.id == item?.product_sku?.color_id
        // );

        // setUserCartID(cartID);
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let obj_val = {
          ...object,
          for_price: +res?.product?.for_25,
          count_value: 1,
        };
        if (obj[res?.product?.id]?.pack) {
          obj_val.pack = obj[res?.product?.id]?.pack;
          obj_val.no_of_colors = obj[res?.product?.id]?.no_of_colors;
          obj_val.sku_id = obj[res?.product?.id]?.sku_id;
        } else {
          obj_val.pack = 25;
          obj_val.no_of_colors = 1;
          obj_val.sku_id = res?.product?.product_varient_skus?.[0]?.id;
        }

        if (obj[res?.product?.id]?.packs_price) {
          obj_val.packs_price = obj[res?.product?.id]?.packs_price;
        }

        let final = {
          ...obj,
          [res?.product?.id]: obj_val,
        };

        setCartData(final);
        const temp = [];
        temp?.push(res?.product);

        // const image = [];
        // temp?.map((item, ind) => {
        //   item?.colorImages?.map((color) => {
        //     let color_id_finder = item?.colors?.find(
        //       (i) => i?.id == color?.color_id
        //     );
        //     image.push({
        //       ...color,
        //       color: color_id_finder,
        //     });
        //   });
        // });

        // if (item?.product_sku?.id) {
        //   setSelectedColorId(colorFinder?.name);
        //   setSelectColorId(colorFinder?.id);
        //   setBigImg(findIndex);
        // } else {
        //   setSelectedColorId(res?.product?.colors?.[0]?.name);
        //   setSelectColorId(res?.product?.colors?.[0]?.id);
        //   setBigImg(0);
        // }

        // setStyle(ima)
        // console.log("temps", temps);
        // let finalArr = [
        //   {
        //     ...res?.product,
        //     images: image,
        //   },
        // ];
        // setViewList(finalArr);
        const image = [];
        temp?.map((item, ind) => {
          item?.colorImages?.map((color) => {
            let color_id_finder = item?.colors?.find(
              (i) => i?.id == color?.color_id
            );

            image.push({
              ...color,
              color: color_id_finder,
            });
          });
        });

        const uniqueColors = [];
        temp?.map((item, ind) => {
          item?.product_varient_skus?.map((color) => {
            let finderImage = image?.find(
              (i) => i?.color_id == color?.color_id
            );

            uniqueColors.push(finderImage);
          });
        });
        const uniqueId = new Map(uniqueColors.map((c) => [c.color_id, c]));
        const DefultImages = [...uniqueId.values()];
        // console.log(DefultImages);

        let colorFinder = DefultImages?.find(
          (i) => i?.color_id == item?.product_sku?.color_id
        );
        let findIndex = DefultImages?.findIndex(
          (i) => i?.color_id == item?.product_sku?.color_id
        );

        if (item?.product_sku?.id) {
          setSelectedColorId(colorFinder?.color?.name);
          setSelectColorId(colorFinder?.color?.id);
          setBigImg(findIndex);
        } else {
          setSelectedColorId(DefultImages?.[0]?.color?.name);
          setSelectColorId(DefultImages?.[0]?.color?.id);
          setBigImg(0);
        }

        let finalArr = [
          {
            ...res?.product,
            images: DefultImages,
          },
        ];
        setViewList(finalArr)
        if (finalArr.length > 0) {
          showPopupHander();
        }

        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  // ADD CART
  const getAddToCart = (skuId, productId, qty, packsPrice) => {
    let pack_value = cartData?.[productId]?.pack;

    let packs_val = +cartData?.[productId]?.packs_price
      ? +cartData?.[productId]?.packs_price
      : +cartData?.[productId]?.for_price;

    let count_value = cartData?.[productId]?.count
      ? +cartData?.[productId]?.count
      : +cartData?.[productId]?.count_value;

    let no_of_colors = cartData?.[productId]?.no_of_colors
      ? +cartData?.[productId]?.no_of_colors
      : 1;

    // console.log("packsPrice", packsPrice);

    let temp = [];

    temp.push({
      product_id: productId,
      product_sku_id: skuId,
      no_of_colors: no_of_colors,
      qty: qty ? qty : count_value,
      price: +packsPrice ? +packsPrice : +packs_val,
    });

    // console.log("temp", temp);

    const formData = new FormData();
    formData.append("type", 3);
    // formData.append("no_of_packs", pack_value);
    formData.append("no_of_packs", 1);

    if (temp?.length > 0) {
      temp?.map((item, ind) => {
        formData.append(`cart_details[${ind}][qty]`, item?.qty);
        formData.append(`cart_details[${ind}][product_id]`, item?.product_id);

        if (item?.product_sku_id) {
          formData.append(
            `cart_details[${ind}][product_sku_id]`,
            item?.product_sku_id
          );
        }
        formData.append(`cart_details[${ind}][price]`, item?.price);
        formData.append(
          `cart_details[${ind}][no_of_colors]`,
          item?.no_of_colors
        );
      });
    }

    // console.log("productId", productId);

    addToCartApi(formData)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        // if (!qty) {
        showPopupHander();
        // }
        const temp = [];
        temp.push(viewId);
        setTick(temp);
        getCartApi();
        // console.log(res);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log("err", err);
      });
  };

  //PRODUCT PRICE LIST
  const productPrice = () => {
    const formData = new FormData();
    if (selectedIds?.length > 0) {
      selectedIds?.map((item, ind) => {
        formData.append(`product_ids[${ind}]`, item);
      });
    }
    productPriceApi(formData)
      .unwrap()
      .then((res) => {
        const price = res?.total_price;
        setPacksPrice(price?.["100"]);

        setPriceList(price);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const showPopupHander = async (e) => {
    // setPopData(e);
    setShowPopup(!showPopup);
  };
  const addProductHandler = (e) => {
    console.log("products:", e);
    setTick(!tick);
  };
  // console.log(popData);

  // TICK PRODUCT
  const onClickProduct = (product) => {
    const formData = new FormData();
    setViewId(product);
    formData?.append("product_ids", product);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: product })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setSelectedColorId(res?.product?.colors?.[0]?.id);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let final = {
          ...obj,
          [res?.product?.id]: {
            ...object,
            for_price: +res?.product?.for_25,
            pack: 25,
            count_value: 1,
          },
        };

        // setCartData(final);

        if (!Token) {
          addLocalCart(
            res?.product?.product_varient_skus?.[0]?.id,
            res?.product?.id,
            25,
            res?.product?.for_25,
            "no_cart",
            "no_pop_up"
          );
        } else {
          const temp = [];
          temp?.push(res?.product);

          const list = [];
          const image = [];
          temp?.map((item, ind) => {
            item?.colorImages?.map((color) => {
              list.push({ img: color?.image_url });
            });
            item?.colors?.map((hex) => {
              list?.push({ ...hex, hex_code: hex?.hex_code });
            });

            image?.push({
              ...item,
              images: list,
            });
          });
          // console.log(image);
          // setStyle(ima)
          setViewList(image);

          let tempPro = [];

          tempPro.push({
            product_id: res?.product?.id,
            product_sku_id: res?.product?.product_varient_skus?.[0]?.id,
            no_of_colors: 1,
            qty: 25,
            price: res?.product?.for_25,
          });

          const formData = new FormData();
          formData.append("type", 3);
          formData.append("no_of_packs", 1);

          if (tempPro?.length > 0) {
            tempPro?.map((item, ind) => {
              formData.append(`cart_details[${ind}][qty]`, item?.qty);
              formData.append(
                `cart_details[${ind}][product_id]`,
                item?.product_id
              );

              if (item?.product_sku_id) {
                formData.append(
                  `cart_details[${ind}][product_sku_id]`,
                  item?.product_sku_id
                );
              }
              formData.append(`cart_details[${ind}][price]`, item?.price);
              formData.append(
                `cart_details[${ind}][no_of_colors]`,
                item?.no_of_colors
              );
            });
          }

          // console.log("productId", productId);

          addToCartApi(formData)
            .unwrap()
            .then((resp) => {
              dispatch(saveLoader(false));
              setCartBtn(false);
              const temp = [];
              temp.push(viewId);
              setTick(temp);
              getCartApi();
              // console.log(res);
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setCartBtn(false);
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(saveLoader(false));
        setCartBtn(false);
      });
  };

  const calculations = (qty, id, packs, type) => {
    let qty_val;
    if (type == "decrement") {
      qty_val = +qty - 1;
    } else {
      qty_val = +qty + 1;
    }

    let qty_sum = +qty_val * +cartData?.[id]?.pack;

    // if (+qty_sum <= 1000) {
    let obj = {};
    if (+qty_sum >= 1 && +qty_sum <= 25) {
      setPacksPrice(+ListPrice?.for_25);
      obj.packs_price = +ListPrice?.for_25;
    } else if (+qty_sum > 25 && +qty_sum <= 50) {
      setPacksPrice(+ListPrice?.for_50);
      obj.packs_price = +ListPrice?.for_50;
    } else if (+qty_sum > 50 && +qty_sum <= 100) {
      setPacksPrice(+ListPrice?.for_100);
      obj.packs_price = +ListPrice?.for_100;
    } else if (+qty_sum > 100 && +qty_sum <= 150) {
      setPacksPrice(+ListPrice?.for_150);
      obj.packs_price = +ListPrice?.for_150;
    } else if (+qty_sum > 150 && +qty_sum <= 250) {
      setPacksPrice(+ListPrice?.for_250);
      obj.packs_price = +ListPrice?.for_250;
    } else if (+qty_sum > 250 && +qty_sum <= 500) {
      setPacksPrice(+ListPrice?.for_500);
      obj.packs_price = +ListPrice?.for_500;
    } else if (+qty_sum > 500 && +qty_sum <= 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    } else if (+qty_sum > 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    }

    obj.pack = +packs;
    obj.count = +qty_val;
    obj.for_price = +cartData?.[id]?.for_price;
    obj.no_of_colors = +cartData?.[id]?.no_of_colors;

    let final = {
      ...cartData,
      [id]: obj,
    };

    setCartData(final);

    if (+cartData?.[id]?.cart_id) {
      obj.cart_id = +cartData?.[id]?.cart_id;
      varients(
        id,
        qty_val,
        obj?.packs_price ? obj?.packs_price : obj.for_price
      );
    }
    // }
  };

  const customQty = (
    no_of_packs,
    productId,
    skuId,
    colorcount,
    packs_val,
    qty,
    item
  ) => {
    const no_of_pack = no_of_packs ? +no_of_packs : +noOfPack;

    if (no_of_pack?.length == 0) {
      setNoOfPackErr(true);
    } else if (cartData?.[productId]?.cart_id) {
      if (!Token) {
        customQtyLocal(no_of_pack, productId, qty);
      } else {
        let temp = [];

        let obj = {
          product_id: productId,
          product_sku_id: skuId,
          no_of_colors: cartData?.[productId]?.no_of_colors,
        };

        if (item?.id) {
          if (+no_of_pack >= 1 && +no_of_pack <= 25) {
            obj.price = +item?.product?.for_25;
          } else if (+no_of_pack > 25 && +no_of_pack <= 50) {
            obj.price = +item?.product?.for_50;
          } else if (+no_of_pack > 50 && +no_of_pack <= 100) {
            obj.price = +item?.product?.for_100;
          } else if (+no_of_pack > 100 && +no_of_pack <= 150) {
            obj.price = +item?.product?.for_150;
          } else if (+no_of_pack > 150 && +no_of_pack <= 250) {
            obj.price = +item?.product?.for_250;
          } else if (+no_of_pack > 250 && +no_of_pack <= 500) {
            obj.price = +item?.product?.for_500;
          } else if (+no_of_pack > 500 && +no_of_pack <= 1000) {
            obj.price = +item?.product?.for_1000;
          } else if (+no_of_pack > 1000) {
            obj.price = +item?.product?.for_1000;
          }
          obj.qty = noOfPack;
        } else {
          obj.price = +packs_val;
          obj.qty = no_of_packs;
        }

        temp.push(obj);

        const formData = new FormData();
        formData.append("type", 3);
        formData.append("no_of_packs", 1);

        if (temp?.length > 0) {
          temp?.map((item, ind) => {
            formData.append(`cart_details[${ind}][qty]`, item?.qty);
            formData.append(
              `cart_details[${ind}][product_id]`,
              item?.product_id
            );

            if (item?.product_sku_id) {
              formData.append(
                `cart_details[${ind}][product_sku_id]`,
                item?.product_sku_id
              );
            }
            formData.append(`cart_details[${ind}][price]`, item?.price);
            formData.append(
              `cart_details[${ind}][no_of_colors]`,
              item?.no_of_colors
            );
          });
        }
        addToCartApi(formData)
          .unwrap()
          .then((res) => {
            setNoOfPack("");
            setNoOfPackErr(false);
            dispatch(saveLoader(false));
            setCartBtn(false);
            if (!qty) {
              showPopupHander();
            }
            const temp = [];
            temp.push(viewId);
            setTick(temp);
            getCartApi();
            // console.log(res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            console.log(err);
          });
      }
    } else {
      let obj = { ...cartData };
      let final = {
        ...obj[productId],
        count: +no_of_packs,
        packs_price: +packs_val,
      };

      setCartData((val) => ({ ...val, [productId]: final }));
    }
  };

  useEffect(() => {
    if (selectedIds?.length > 1) {
      productPrice();
    }
  }, [selectedIds]);

  const varientsNew = (id, qty, packsPrice, colorID, noOfColors, type) => {
    let codeFinder = skuCodeList?.find((i) => i?.color_id == colorID);
    if (codeFinder?.id) {
      let pack_value = cartData?.[id]?.pack;

      let packs_val = +cartData?.[id]?.packs_price
        ? +cartData?.[id]?.packs_price
        : +cartData?.[id]?.for_price;

      if (!Token) {
        updateLocalCart(id, qty, packs_val, codeFinder, noOfColors, type);
      } else {
        let count_value = cartData?.[id]?.count
          ? +cartData?.[id]?.count
          : +cartData?.[id]?.count_value;

        let no_of_colors = noOfColors
          ? noOfColors
          : cartData?.[id]?.no_of_colors
          ? +cartData?.[id]?.no_of_colors
          : 1;

        let temp = [];

        temp.push({
          product_id: id,
          product_sku_id: codeFinder?.id,
          no_of_colors: no_of_colors,
          qty: qty ? qty : count_value,
          price: +packsPrice ? +packsPrice : +packs_val,
        });

        const formData = new FormData();
        formData.append("type", 3);
        // formData.append("no_of_packs", pack_value);
        formData.append("no_of_packs", 1);

        if (temp?.length > 0) {
          temp?.map((item, ind) => {
            formData.append(`cart_details[${ind}][qty]`, item?.qty);
            formData.append(
              `cart_details[${ind}][product_id]`,
              item?.product_id
            );

            if (item?.product_sku_id) {
              formData.append(
                `cart_details[${ind}][product_sku_id]`,
                item?.product_sku_id
              );
            }
            formData.append(`cart_details[${ind}][price]`, item?.price);
            formData.append(
              `cart_details[${ind}][no_of_colors]`,
              item?.no_of_colors
            );
          });
        }

        addToCartApi(formData)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            // if (!qty) {
            //   showPopupHander();
            // }
            // const temp = [];
            // temp.push(viewId);
            // setTick(temp);
            getCartApi();
            // console.log(res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            console.log(err);
          });
      }
    }
  };

  // Db Intialize starts
  const createDB = () => {
    // var open = window.indexedDB.open("CartDB");
    var open = indexedDB.open("CartDB");

    open.onupgradeneeded = function () {
      let db = open.result;
      if (!db.objectStoreNames.contains("CartTable")) {
        db.createObjectStore("CartTable");
      }
    };

    open.onsuccess = function () {
      let db = open.result;
      setDataBase(db);
      console.log("success!");
    };

    open.onerror = function () {
      console.log(open.error);
    };
  };

  // GET DATABASE
  const getDB = (skuId) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("bulk");
    data.onsuccess = (eve) => {
      // console.log("eve.target.result", eve.target.result);
      let responce = eve.target.result;

      const temp = [];
      let tempArr = [];
      responce?.cartDetail?.map((item) => {
        temp?.push(item?.product?.id);
        setTick(temp);
      });

      if (responce?.total_amount) {
        let qtys = responce?.no_of_packs;
        let total_price_cart;
        responce?.cartDetail?.map((item, ind) => {
          let qty_val = item?.product?.[`for_${qtys}`];
          let sum = +item?.qty * qtys;
          let obj = {
            for_price: +qty_val,
            pack: responce?.no_of_packs,
            count: +item?.qty,
            cart_id: 10,
            no_of_colors: item?.no_of_colors,
            product_id: item?.product?.id,
            sku_id: skuId ? skuId : item?.product_sku_id,
          };

          if (qty_val) {
            obj.for_price = +qty_val;
          } else {
            obj.for_price = +qtys;
          }

          if (+sum >= 1 && +sum <= 25) {
            obj.packs_price = +item?.product?.for_25;
            total_price_cart = +item?.product?.for_25;
          } else if (+sum > 25 && +sum <= 50) {
            obj.packs_price = +item?.product?.for_50;
            total_price_cart = +item?.product?.for_50;
          } else if (+sum > 50 && +sum <= 100) {
            obj.packs_price = +item?.product?.for_100;
            total_price_cart = +item?.product?.for_100;
          } else if (+sum > 100 && +sum <= 150) {
            obj.packs_price = +item?.product?.for_150;
            total_price_cart = +item?.product?.for_150;
          } else if (+sum > 150 && +sum <= 250) {
            obj.packs_price = +item?.product?.for_250;
            total_price_cart = +item?.product?.for_250;
          } else if (+sum > 250 && +sum <= 500) {
            obj.packs_price = +item?.product?.for_500;
            total_price_cart = +item?.product?.for_500;
          } else if (+sum > 500 && +sum <= 1000) {
            obj.packs_price = +item?.product?.for_1000;
            total_price_cart = +item?.product?.for_1000;
          } else if (+sum > 1000) {
            obj.packs_price = +item?.product?.for_1000;
            total_price_cart = +item?.product?.for_1000;
          }

          tempArr.push({
            ...item,
            total_price: total_price_cart,
          });

          // console.log("obj", obj);

          setCartData((val) => ({
            ...val,
            [item?.product?.id]: obj,
          }));
        });

        setPack(responce?.no_of_packs);
        setTotalPrice(responce?.total_amount);
        setTotalPerPrice(responce?.price_per_pack);
        setCartList(tempArr);
        setPriceList(responce?.pack_prices);
      } else {
        setCartList([]);
        setPriceList({});
      }

      dispatch(saveLoader(false));
      setCartBtn(false);
    };

    data.onerror = function () {
      console.log(data.error);
    };
  };

  // ADD LOCAL CART
  const addLocalCart = (skuId, productId, qty, packsPrice, type, method) => {
    let tx = dataBase.transaction("CartTable", "readwrite");
    let store = tx.objectStore("CartTable");

    let pack_value = +cartData?.[productId]?.count
      ? cartData?.[productId]?.count
      : cartData?.[productId]?.pack;

    let packs_val = +cartData?.[productId]?.packs_price
      ? +cartData?.[productId]?.packs_price
      : +cartData?.[productId]?.for_price;

    let count_value = cartData?.[productId]?.count
      ? +cartData?.[productId]?.count
      : +cartData?.[productId]?.count_value;

    let no_of_colors = cartData?.[productId]?.no_of_colors
      ? +cartData?.[productId]?.no_of_colors
      : 1;

    let temp = [];
    let pro_temp = [];

    if (type == "no_cart") {
      // console.log("type");
      temp.push({
        product_id: +productId,
        product_sku_id: skuId,
        no_of_colors: no_of_colors,
        qty: +qty ? +qty : count_value,
        price: +packsPrice ? +packsPrice : +packs_val,
      });
    }

    Object.keys(cartData).map((item) => {
      if (+item !== +productId && cartData?.[item]?.cart_id == 10) {
        console.log("type2");
        let qty_arr = cartData?.[item]?.count
          ? +cartData?.[item]?.count
          : +cartData?.[item]?.count_value;
        temp.push({
          product_id: +item,
          product_sku_id: cartData?.[item]?.sku_id,
          no_of_colors: cartData?.[item]?.no_of_colors,
          qty: +qty_arr,
          price: cartData?.[item]?.packs_price
            ? +cartData?.[item]?.packs_price
            : +cartData?.[item]?.for_price,
        });
      } else {
        console.log("type3");
        temp.push({
          product_id: +productId,
          product_sku_id: skuId,
          no_of_colors: no_of_colors,
          qty: +qty ? +qty : count_value,
          price: +packsPrice ? +packsPrice : +packs_val,
        });
      }
    });

    // console.log("temp", temp);

    const ids = temp.map(({ product_id }) => product_id);
    const filtered = temp.filter(
      ({ product_id }, index) => !ids.includes(product_id, index + 1)
    );

    let prices_pack = {
      25: 0,
      50: 0,
      100: 0,
      150: 0,
      250: 0,
      500: 0,
      1000: 0,
    };

    const total_amount_arr = [];

    // console.log("filtered", filtered);

    filtered.map((item, ind) => {
      let finder = productList?.find((i) => i?.id == item?.product_id);
      let finder_sku = skuList?.find((i) => i?.id == item?.product_sku_id);
      let qty_sum = cartData?.[item?.product_id]?.pack
        ? cartData?.[item?.product_id]?.pack *
          +cartData?.[item?.product_id]?.count
        : 25 * +cartData?.[item?.product_id]?.count;

      let total_value;

      let pack_sizes = [25, 50, 100, 150, 250, 500, 1000];
      let quantity_1 = +item?.qty;
      let quantity_3 = +item?.price;
      let quantity_4 = +item?.price;

      // console.log("quantity_1", quantity_1);

      pack_sizes.map((price) => {
        let quantity = +quantity_1 * +price;

        // console.log("quantity", quantity);

        if (+quantity >= 1 && +quantity <= 25) {
          prices_pack[price] += finder?.[`for_${25}`] * +quantity_1;
        } else if (+quantity > 25 && +quantity <= 50) {
          prices_pack[price] += finder?.[`for_${50}`] * +quantity_1;
        } else if (+quantity > 50 && +quantity <= 100) {
          prices_pack[price] += finder?.[`for_${100}`] * +quantity_1;
        } else if (+quantity > 100 && +quantity <= 150) {
          prices_pack[price] += finder?.[`for_${150}`] * +quantity_1;
        } else if (+quantity > 150 && +quantity <= 250) {
          prices_pack[price] += finder?.[`for_${250}`] * +quantity_1;
        } else if (+quantity > 250 && +quantity <= 500) {
          prices_pack[price] += finder?.[`for_${500}`] * +quantity_1;
        } else if (+quantity > 500 && +quantity <= 1000) {
          prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
        } else if (+quantity > 1000) {
          prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
        }
      });

      if (+quantity_1 >= 1 && +quantity_1 <= 25) {
        quantity_3 = finder?.[`for_${25}`] * quantity_1;
        quantity_4 = finder?.[`for_${25}`];
      } else if (+quantity_1 > 25 && +quantity_1 <= 50) {
        quantity_3 = finder?.[`for_${50}`] * quantity_1;
        quantity_4 = finder?.[`for_${50}`];
      } else if (+quantity_1 > 50 && +quantity_1 <= 100) {
        quantity_3 = finder?.[`for_${100}`] * quantity_1;
        quantity_4 = finder?.[`for_${100}`];
      } else if (+quantity_1 > 100 && +quantity_1 <= 150) {
        quantity_3 = finder?.[`for_${150}`] * quantity_1;
        quantity_4 = finder?.[`for_${150}`];
      } else if (+quantity_1 > 150 && +quantity_1 <= 250) {
        quantity_3 = finder?.[`for_${250}`] * quantity_1;
        quantity_4 = finder?.[`for_${250}`];
      } else if (+quantity_1 > 250 && +quantity_1 <= 500) {
        quantity_3 = finder?.[`for_${500}`] * quantity_1;
        quantity_4 = finder?.[`for_${500}`];
      } else if (+quantity_1 > 500 && +quantity_1 <= 1000) {
        quantity_3 = finder?.[`for_${1000}`] * quantity_1;
        quantity_4 = finder?.[`for_${1000}`];
      } else if (+quantity_1 > 1000) {
        quantity_3 = finder?.[`for_${1000}`] * quantity_1;
        quantity_4 = finder?.[`for_${1000}`];
      }

      // console.log("quantity_3", quantity_3);
      // console.log("quantity_4", quantity_4);

      // console.log("prices_pack", prices_pack);
      total_amount_arr.push(quantity_3);

      let obj = {
        ...item,
        price: quantity_4,
        product: finder,
        product_sku: finder_sku,
        total_price: quantity_4,
      };
      pro_temp.push(obj);
    });

    // console.log("total_amount_arr", total_amount_arr);

    let total_amount_arr_to_one = total_amount_arr.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    // console.log("total_amount_arr_to_one", total_amount_arr_to_one);

    let final = {
      no_of_packs: 1,
      price_per_pack: Number(total_amount_arr_to_one).toFixed(2),
      total_amount: Number(total_amount_arr_to_one).toFixed(2),
      cartDetail: pro_temp,
      pack_prices: prices_pack,
    };

    // console.log("final", final);

    let request = store.put(final, "bulk");

    request.onsuccess = function () {
      console.log("success!");
      if (!cartData?.[productId]?.cart_id && method !== "no_pop_up") {
        showPopupHander();
      }
      const temp = [];
      temp.push(viewId);
      setTick(temp);
      getDB(skuId);
    };

    request.onerror = function () {
      console.log(request.error);
    };
  };

  // PACK QTY CHANGE AND CUSTOM QTY CHANGE
  const customQtyLocal = (no_of_packs, productId, qty) => {
    const no_of_pack = no_of_packs ? no_of_packs : noOfPack;

    if (no_of_pack?.length == 0) {
      setNoOfPackErr(true);
    } else {
      let tx = dataBase.transaction("CartTable", "readonly");
      let store = tx.objectStore("CartTable");

      let data = store.get("bulk");
      data.onsuccess = (eve) => {
        let txs = dataBase.transaction("CartTable", "readwrite");
        let stored = txs.objectStore("CartTable");
        let cart_data = eve.target.result;
        let obj = { ...cart_data };
        let arr = [...obj.cartDetail];
        let result = obj?.pack_prices;
        const total_amount_arr = [];

        let inx = arr.findIndex((i) => i?.product_id == productId);

        let qtyPack;

        if (+no_of_pack >= 1 && +no_of_pack <= 25) {
          qtyPack = 25;
        } else if (+no_of_pack > 25 && +no_of_pack <= 50) {
          qtyPack = 50;
        } else if (+no_of_pack > 50 && +no_of_pack <= 100) {
          qtyPack = 100;
        } else if (+no_of_pack > 100 && +no_of_pack <= 150) {
          qtyPack = 150;
        } else if (+no_of_pack > 150 && +no_of_pack <= 250) {
          qtyPack = 250;
        } else if (+no_of_pack > 250 && +no_of_pack <= 500) {
          qtyPack = 500;
        } else if (+no_of_pack > 500 && +no_of_pack <= 1000) {
          qtyPack = 1000;
        } else if (+no_of_pack > 1000) {
          qtyPack = 1000;
        }

        arr[inx] = {
          ...arr[inx],
          qty: no_of_packs,
          price: arr[inx].product[`for_${qtyPack}`],
          total_price: arr[inx].product[`for_${qtyPack}`],
        };

        arr.map((item, ind) => {
          total_amount_arr.push(item?.qty * item?.total_price);
        });

        let total_amount_arr_to_one = total_amount_arr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );

        obj = {
          ...obj,
          cartDetail: arr,
        };

        let final = {
          ...obj,
          no_of_packs: 1,
          price_per_pack: Number(total_amount_arr_to_one).toFixed(2),
          total_amount: Number(total_amount_arr_to_one).toFixed(2),
        };

        let request = stored.put(final, "bulk");

        request.onsuccess = function () {
          getDB();
          setNoOfPack("");
          setNoOfPackErr(false);
        };

        request.onerror = function () {
          console.log(request.error);
        };
      };
    }
  };

  // UPDATE LOCAL CART
  const updateLocalCart = (id, qty, packsPrice, colors, noOfColors, type) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("bulk");
    data.onsuccess = (eve) => {
      let cart_data = eve.target.result;
      let obj = { ...cart_data };
      let arr = [...obj?.cartDetail];
      let index = arr.findIndex((i) => i.product_id == id);

      // EDIT
      let txs = dataBase.transaction("CartTable", "readwrite");
      let stored = txs.objectStore("CartTable");

      arr[index] = {
        ...arr[index],
      };

      if (type == "logo") {
        arr[index].no_of_colors = noOfColors;
      }

      if (type == "color") {
        arr[index].product_sku = colors;
        arr[index].product_sku_id = colors?.id;
      }

      let final = {
        ...obj,
        cartDetail: arr,
      };

      let request = stored.put(final, "bulk");

      request.onsuccess = function () {
        console.log("success!");
        getDB();
      };

      request.onerror = function () {
        console.log(request.error);
      };

      // console.log("final", final);
    };
  };

  // DELETE LOCAL CART
  const deleteLocalCart = (id) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("bulk");
    data.onsuccess = (eve) => {
      let cart_data = eve.target.result;

      // DELETE CART
      let obj = { ...cart_data };
      let arr = [...obj?.cartDetail];
      let temp = [];
      let index = arr.findIndex((i) => i.product_id == id);
      // console.log("index", index, id);
      arr.splice(index, 1);

      let txs = dataBase.transaction("CartTable", "readwrite");
      let stored = txs.objectStore("CartTable");

      let total_amount_arr = [];

      let qtyPack;

      // console.log("arr", arr);

      arr.map((item, ind) => {
        total_amount_arr.push(item?.qty * item?.total_price);
        temp.push(item?.product_id);
      });

      let total_amount_arr_to_one = total_amount_arr.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      obj = {
        ...obj,
        cartDetail: arr,
      };

      let final = {
        ...obj,
        no_of_packs: 1,
        price_per_pack: Number(total_amount_arr_to_one).toFixed(2),
        total_amount: Number(total_amount_arr_to_one).toFixed(2),
      };

      // console.log("final", final);

      let request = stored.put(final, "bulk");

      request.onsuccess = function () {
        console.log("success!");
        let obj = { ...cartData };
        delete obj[id];
        setCartData(obj);
        setTick(temp);
        setShowPopup(false);
        getDB();
      };

      request.onerror = function () {
        console.log(request.error);
      };
    };

    data.onerror = function () {
      console.log(data.error);
    };
  };

  useEffect(() => {
    if (!Token) {
      createDB();
    } else {
      getCartApi();
    }
  }, []);

  useEffect(() => {
    if (dataBase?.name) {
      getDB();
    }
  }, [dataBase]);

  // console.log("cartData", cartData);

  return (
    <div className="product-screen d-flex flex-column flex-md-row">
      {/* <LeftProduct />
      <RightProducts
        tick={tick}
        addProductHandler={addProductHandler}
        showPopupHander={showPopupHander}
      /> */}
      <BulkProductFullScreen
        setValues={setValues}
        values={values}
        tick={tick}
        categoryList={categoryList}
        addProductHandler={addProductHandler}
        showPopupHander={showPopupHander}
        setViewId={setViewId}
        setTick={setTick}
        onClickProduct={onClickProduct}
        getAddToCart={getAddToCart}
        getProductView={getProductView}
        cartData={cartData}
        deleteCart={deleteCart}
        userCartID={userCartID}
        product={product}
        setNormalId={setNormalId}
        SetPriceSort={SetPriceSort}
        priceSort={priceSort}
      />
      {cartList?.length > 0 && (
        <BulkProductCart
          pack="build"
          tick={tick}
          categoryList={categoryList}
          addProductHandler={addProductHandler}
          cartList={cartList}
          priceList={priceList}
          setShowPopup={showPopupHander}
          deleteCart={deleteCart}
          getProductView={getProductView}
          packs={pack}
          totalPrice={totalPrice}
          totalPerPrice={totalPerPrice}
          showoption={showoption}
          setShowOption={setShowOption}
          noOfPackErr={noOfPackErr}
          noOfPack={noOfPack}
          setNoOfPack={setNoOfPack}
          customQty={customQty}
          cartData={cartData}
          // setPricePack={setPricePack}
          // setPricePack={setPricePack}
        />
      )}
      {showPopup && (
        <ProductPopupComponent
          popData={popData}
          showPopupHander={showPopupHander}
          varients={varients}
          setSelectedColorId={setSelectedColorId}
          selectedColorId={selectedColorId}
          setSelectColorId={setSelectColorId}
          selectColorId={selectColorId}
          showPopupHander={showPopupHander}
          viewList={viewList}
          imagesid={imagesid}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          count={count}
          setCount={setCount}
          productPrice={productPrice}
          setTick={setTick}
          packsPrice={packsPrice}
          getAddToCart={getAddToCart}
          setColorCount={setColorCount}
          colorcount={colorcount}
          bigImg={bigImg}
          setBigImg={setBigImg}
          setMaterial={setMaterial}
          material={material}
          setSize={setSize}
          size={size}
          setStyle={setStyle}
          style={style}
          pack={pack}
          calculations={calculations}
          setCartData={setCartData}
          cartData={cartData}
          userCartID={userCartID}
          deleteCart={deleteCart}
          cartBtn={cartBtn}
          deleteBtn={deleteBtn}
          customQty={customQty}
          varientsNew={varientsNew}
        />
      )}
      {tick?.length > 0 && (
        <button
          // onClick={addProductHandler}
          className="cust-btn d-flex ac-jc d-xl-none mobile-cart"
        >
          <ShoppingBagIcon />
        </button>
      )}
    </div>
  );
};

export default BulkProductScreen;

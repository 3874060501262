// @ts-nocheck
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { closeIcon, logist, product1, ship1, ship2 } from "../assets/img";
import RequiredSummary from "../component/Order/RequiredSummary";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateQuantity from "../component/MyProductDetail/UpdateQuantity";
import SelectRecipients from "../component/SelectProduct/SelectRecipients";
import SelectProduct from "../component/SelectProduct/SelectProduct";
import ProductsAndQuantity from "../component/SelectProduct/ProductsAndQuantity";
import DoneIcon from "@mui/icons-material/Done";
import AddExistingRecipient from "../component/SelectProduct/AddExistingRecipient";
import NewContact from "../component/SelectProduct/NewContact";
import Modal from "react-bootstrap/Modal";
import AddressPopup from "../component/Popup/AddressPopup";
import { useAddressListOrderMutation } from "../redux/api/api";
import { countryList} from "../redux/api/DummyJson";
import { Update } from "@mui/icons-material";

const SelectProductScreen = () => {
  const location = useLocation();
  const project = location?.state;
  // console.log(project, "og");

  const navigate = useNavigate();
  const [OpenSummery, setOpenSummery] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [storagePopup, setStoragePopup] = useState(false);
  const [shipPopup, setShipPopup] = useState(false);
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState("Add New");
  const [btnChange, setBtnChange] = useState(0);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [LandMark, setLandMark] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [num, setNum] = useState("");
  const [alternum, setAlterNum] = useState("");
  const [gst, setGst] = useState("");
  const [country, setCountry] = useState("");
  const [listState, setListState] = useState([]);

  const [address1Err, setAddress1Err] = useState(false);
  const [firstNameErr, setfirstNameErr] = useState(false);

  const [type, setType] = useState(1);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("");

  const [selectRec, setSelectRec] = useState(false);
  const [selectSingle, setSelectSingle] = useState(false);
  const [enableReci, setEnableReci] = useState(false);
  const [addReciPopup, setAddReciPopup] = useState(false);
  const [breakdownList, setBreakdownList] = useState({});
  const [list,setList] =useState([]);
  const [selected,setSelected]=useState();
  const [update, setUpdate] = useState({});

  const [AddressList] = useAddressListOrderMutation();

  const getAddresslist = (searchs, types, orders) => {
    setSearch(searchs);
    setType(types);
    setOrder(orders);
    const formdata = new FormData();
    if (searchs) {
      formdata.append("search", searchs);
    }
    if (orders) {
      formdata.append("order", orders);
    }
    formdata.append("project_id", project?.id);
    if (types) {
      formdata.append("type", types);
    }
    AddressList(formdata)
      .unwrap()
      .then((res) => {
        setList(res?.lists);
        console.log(res);

      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAddresslist("", 1, "");
  }, []);
  const openSummeryHandler = () => {
    setOpenSummery(!OpenSummery);
    setShowUpdate(false);
  };
  const showClickHandler = () => {
    setShowUpdate(false);
    // setStoragePopup(!storagePopup);
    setStoragePopup(false);
    setShipPopup(false);
  };

  const poppupAddressToggle = () => {
    setShowType("Add New");
    setShow(false);
  };

  const poppupAddressEditToggle = () => {
    setShowType("Edit");
    setShow(true);
  
  };

  const viewBreakdown = (item) => {
    setBreakdownList(item);
    setShowUpdate(true);
  };
  const getState = (value) => {
    setCountry(value);
    let finder = countryList?.find((i) => i?.name == value);
    setListState(finder?.states);
  };
  console.log(selected,'selected')

  const getData = (item) => {
    setSelected(item);
    if (item?.first_name) {
      setfirstName(item?.first_name);
    }

    if (item?.last_name) {
      setLastname(item?.last_name);
    }

    if (item?.email_id) {
      setEmail(item?.email_id);
    }

    if (item?.address1) {
      setAddress1(item?.address1);
    }

    if (item?.address2) {
      setAddress2(item?.address2);
    }

    if (item?.landmark) {
      setLandMark(item?.landmark);
    }

    if (item?.country) {
      setCountry(item?.country);
      let finder = countryList?.find((i) => i?.name == item?.country);
      setListState(finder?.states);
    }

    if (item?.state) {
      setState(item?.state);
    }

    if (item?.city) {
      setCity(item?.city);
    }

    if (item?.pincode) {
      setPincode(item?.pincode);
    }

    if (item?.phone_no) {
      setNum(item?.phone_no);
    }

    if (item?.alter_phone_no) {
      setAlterNum(item?.alter_phone_no);
    }

    if (item?.gst_no) {
      setGst(item?.gst_no);
    }
    setUpdate(item);
    setShow(true);
  };
  // const getView = (id) => {
  //   addressView()
  //     .unwrap()
  //     .then((res) => {
  //       if (res?.status == "success" || res?.data?.status == "success") {
  //         let data = res?.data;
  //         // let reversedComments = data?.comments?.slice().reverse();
  //         // setfirstName(reversedComments);
  //         // setLastname(data.task_date);
  //         // setAddress1(data.task_deadline_date);
  //         // setAddress2(data.project.project_code);
  //         // setAlterNum(data.assignedBy.first_name);
  //         // setNum(data.department.name);
  //         // setState(data.assignedTo.first_name);
  //         // setCountry(data.status);
  //         // setPincode(data.description);
  //         // setCity(data.completion_date);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err");
  //     });
  // };
  // useEffect(() => {
    
  //     getView();
    
  // }, []);

  return (
    <div className="tl-shipment-screen">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2">
        <div className="product-shipment d-flex flex-column flex-lg-row justify-content-between mt-3 mb-5">
          <div className="d-flex ac-jb w-100">
            <div className="w-100 d-flex ac-js w-100">
              <button
                className={`${
                  btnChange == 0 ? "border_click_cust" : "cust-btn "
                } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
                onClick={() => {
                  setBtnChange(0);
                  getAddresslist(search, 1, order);
                }}
              >
                Shipping Address
              </button>
              <button
                className={`${
                  btnChange == 1 ? "border_click_cust" : "cust-btn "
                } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
                onClick={() => {
                  setBtnChange(1);
                  getAddresslist(search, 2, order);
                }}
              >
                Billing Address
              </button>
            </div>
          </div>
        </div>

        <SelectRecipients
          getAddresslist={getAddresslist}
          order={order}
          search={search}
          type={type}
          addReciPopup={addReciPopup}
          setAddReciPopup={setAddReciPopup}
          setShow={setShow}
          setList={setList}
          list={list}
          firstName={firstName}
          setfirstName={setfirstName}
          lastName={lastName}
          setLastname={setLastname}
          email={email}
          setEmail={setEmail}
          address1={address1}
          setAddress1={setAddress1}
          address2={address2}
          setAddress2={setAddress2}
          LandMark={LandMark}
          setLandMark={setLandMark}
          state={state}
          country={country}
          setCountry={setCountry}
          setState={setState}
          city={city}
          setCity={setCity}
          pincode={pincode}
          setPincode={setPincode}
          num={num}
          setNum={setNum}
          alternum={alternum}
          setAlterNum={setAlterNum}
          gst={gst}
          setGst={setGst}
          countryList={countryList}
          setListState={setListState}
          listState={listState}
          selected={selected}
          setSelected={setSelected}
          getData={getData}
          update={update}
          setUpdate={setUpdate}
          selected={selected}
          setSelected={setSelected}
          poppupAddressEditToggle={poppupAddressEditToggle}
        />
      </div>

      {/* <ProductsAndQuantity
        showClickHandler={showClickHandler}
        OpenSummery={OpenSummery}
      /> */}
      <RequiredSummary
        showClickHandler={showClickHandler}
        OpenSummery={OpenSummery}
        showUpdate={setShowUpdate}
        openSummeryHandler={openSummeryHandler}
        list={project}
        viewBreakdown={viewBreakdown}
      />

      <AddExistingRecipient
        showUpdate={showUpdate}
        showClickHandler={showClickHandler}
      />
      <NewContact
        showUpdate={showContact}
        showClickHandler={showClickHandler}
        setShow={setShow}
      />
      {addReciPopup && (
        <div className="custPopup p-3 rounded-4">
          <button
            className="cust-btn"
            onClick={() => {
              setShowUpdate(true);
              setAddReciPopup(false);
            }}
          >
            <h6>Select from Existing</h6>
          </button>
          <button
            className="cust-btn mt-2"
            onClick={() => {
              setShowContact(true);
              setAddReciPopup(false);
            }}
          >
            <h6>Add New Contact</h6>
          </button>
        </div>
      )}

      {show && (
        <AddressPopup
          poppupAddressToggle={poppupAddressToggle}
          showType={showType}
          firstName={firstName}
          setfirstName={setfirstName}
          lastName={lastName}
          setLastname={setLastname}
          email={email}
          setEmail={setEmail}
          address1={address1}
          setAddress1={setAddress1}
          address2={address2}
          setAddress2={setAddress2}
          LandMark={LandMark}
          setLandMark={setLandMark}
          state={state}
          setState={setState}
          city={city}
          setCity={setCity}
          pincode={pincode}
          setPincode={setPincode}
          num={num}
          setNum={setNum}
          alternum={alternum}
          setAlterNum={setAlterNum}
          gst={gst}
          setGst={setGst}
          type={type}
          project={project}
          setListState={setListState}
          listState={listState}
          selected={selected}
          setSelected={setSelected}
          getData={getData}
          update={Update}
          setUpdate={setUpdate}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </div>
  );
};

export default SelectProductScreen;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, URL } from "./constants";
import { getToken } from "../store/userTocken";
import { dashboard } from "../../assets/img";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("token");
      headers.set("Authorization", "Bearer " + token);
      // headers.set("Content-Type", "multipart/form-data");
      // headers.set("Content-Type", "application/json");
      // headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["profileData", "products"],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        method: "POST",
        body: payload,
      }),
    }),

    // REGISTER
    register: builder.mutation({
      query: (payload) => ({
        url: URL.REGISTER,
        method: "POST",
        body: payload,
      }),
    }),

    // FORGOT PASSWORD
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: URL.FORGOT_PASSWORD,
        method: "POST",
        body: payload,
      }),
    }),

    // VERIFY USER
    verifyUser: builder.mutation({
      query: (payload) => ({
        url: URL.VERIFY_PASSWORD,
        method: "POST",
        body: payload,
      }),
    }),

    // RESET PASSWORD
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: URL.RESET_PASSWORD,
        method: "POST",
        body: payload,
      }),
    }),

    // USER EDIT
    userEdit: builder.mutation({
      query: (payload) => ({
        url: URL.USER_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // VERIFY TOKEN
    verifyToken: builder.mutation({
      query: (payload) => ({
        url: URL.VERIFY_TOKEN,
        method: "POST",
        body: payload,
      }),
    }),
    //BANNER
    userBanner: builder.mutation({
      query: (payload) => ({
        url: URL.USER_BANNER,
        method: "POST",
        body: payload,
      }),
    }),
    //TESTIMONIAL LIST
    testiMonial: builder.mutation({
      query: (payload) => ({
        url: URL.TESTIMONIAL,
        method: "POST",
        body: payload,
      }),
    }),
    //BLOGLIST
    blogList: builder.mutation({
      query: (payload) => ({
        url: URL.BLOG_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    //BLOGVIEW
    blog_view: builder.query({
      query: (id) => ({
        url: URL.BLOG_VIEW + id,
      }),
    }),
    //BLOGTAGS
    blogTags: builder.mutation({
      query: (payload) => ({
        url: URL.BLOG_TAGS,
        method: "POST",
        body: payload,
      }),
    }),
    //CATEGORY
    categoryList: builder.mutation({
      query: (payload) => ({
        url: URL.CATEGORIES,
        method: "POST",
        body: payload,
      }),
    }),
    //PRODUCTSLIST
    productList: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCTLIST,
        method: "POST",
        body: payload,
      }),
    }),
    //PRODUCT VIEW
    productView: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PRODUCT_VIEW + id,
        method: "POST",
        body: payload,
      }),
    }),
    //EVENT_LIST
    eventList: builder.mutation({
      query: (payload) => ({
        url: URL.EVENT_List,
        method: "POST",
        body: payload,
      }),
    }),
    //PRESET_LIST
    presetPackList: builder.mutation({
      query: (payload) => ({
        url: URL.PRESET_PACK,
        method: "POST",
        body: payload,
      }),
    }),
    presetPackView: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PRESET_PACK_VIEW + id,
        method: "POST",
        body: payload,
      }),
    }),
    productPriceList: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_PRICE_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    addToCart: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_TO_CART,
        method: "POST",
        body: payload,
        prepareHeaders: (headers) => {
          headers.set("Content-type", "appliation/json");
          headers.set("Accept", "application/json");
          return headers;
        },
      }),
    }),
    updateToCart: builder.mutation({
      query: (payload) => ({
        url: URL.UPDATE_TO_CART,
        method: "POST",
        body: payload,
      }),
    }),
    cartList: builder.mutation({
      query: (payload) => ({
        url: URL.CART_LIST,
        method: "POST",
        body: payload,
        prepareHeaders: (headers) => {
          headers.set("Content-type", "appliation/json");
          headers.set("Accept", "application/json");
          return headers;
        },
      }),
    }),
    deleteCart: builder.mutation({
      query: (payload) => ({
        url: URL.DELETE,
        method: "POST",
        body: payload,
      }),
    }),
    packUpdateApi: builder.mutation({
      query: (payload) => ({
        url: URL.PACK_UPDATE,
        method: "POST",
        body: payload,
      }),
    }),

    productReq: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_REQUEST,
        method: "POST",
        body: payload,
      }),
    }),
    dashboardOrderList: builder.mutation({
      query: (payload) => ({
        url: URL.DASHBOARD_ORDER_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    //Tags
    tagsfilter: builder.query({
      query: () => ({
        url: URL.TAGS_FILTER,
      }),
    }),

    colorList: builder.mutation({
      query: (payload) => ({
        url: URL.COLOR_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS ADD
    addressAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ADDRESS_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS EDIT
    address_Edit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.ADDRESS_EDIT + id,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS LIST
    addressList: builder.mutation({
      query: (payload) => ({
        url: URL.ADDRESS_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS VIEW
    addressView: builder.query({
      query: (id) => URL.ADDRESS_VIEW + id,
    }),

    // ADDRESS DELETE
    addressDelete: builder.query({
      query: (id) => URL.ADDRESS_DELETE + id,
    }),
    //PRESENTATION
    viewPriductDetails: builder.mutation({
      query: (payload) => ({
        url: URL.VIEW_PRODUCT_DETAILS,
        method: "POST",
        body: payload,
      }),
    }),
    viewProjectProduct: builder.mutation({
      query: (payload) => ({
        url: URL.VIEW_PROJECT_PRODUCT_details,
        method: "POST",
        body: payload,
      }),
    }),
    viewProjectProductStatus: builder.mutation({
      query: (payload) => ({
        url: URL.CHANGE_PROJECT_DETAIL_STATUS,
        method: "POST",
        body: payload,
      }),
    }),
    viewProjectDelete: builder.mutation({
      query: (payload) => ({
        url: URL.DELETE_PROJECT_PRODUCT,
        method: "POST",
        body: payload,
      }),
    }),
    viewApproveproject: builder.mutation({
      query: (payload) => ({
        url: URL.APPROVE_PROJECT,
        method: "POST",
        body: payload,
      }),
    }),
    getProductPre: builder.mutation({
      query: (payload) => ({
        url: URL.GET_PRODUCT_PRESENTATION,
        method: "POST",
        body: payload,
      }),
    }),
    createorder: builder.mutation({
      query: (payload) => ({
        url: URL.CREATE_ORDER,
        method: "POST",
        body: payload,
      }),
    }),
    //ORDER
    orderList: builder.mutation({
      query: (payload) => ({
        url: URL.ORDERS_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    getOrderData: builder.mutation({
      query: (payload) => ({
        url: URL.GET_ORDER_DATA,
        method: "POST",
        body: payload,
      }),
    }),
    addressListOrder: builder.mutation({
      query: (payload) => ({
        url: URL.ADDRESS_LIST_ORDER,
        method: "POST",
        body: payload,
      }),
    }),
    addressAddList: builder.mutation({
      query: (payload) => ({
        url: URL.ADDRESS_ADD_ORDER,
        method: "POST",
        body: payload,
      }),
    }),
    addressViewOrder: builder.query({
      query: (id) => URL.VIEW_ADD_ORDER + id,
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyUserMutation,
  useUserEditMutation,
  useVerifyTokenMutation,
  useUserBannerMutation,
  useTestiMonialMutation,
  useBlogListMutation,
  useLazyBlog_viewQuery,
  useBlogTagsMutation,
  useCategoryListMutation,
  useProductListMutation,
  useEventListMutation,
  usePresetPackListMutation,
  usePresetPackViewMutation,
  useProductViewMutation,
  useProductPriceListMutation,
  useAddToCartMutation,
  useUpdateToCartMutation,
  useCartListMutation,
  useDeleteCartMutation,
  usePackUpdateApiMutation,
  useProductReqMutation,
  useDashboardOrderListMutation,
  useLazyTagsfilterQuery,
  useColorListMutation,
  useAddressAddMutation,
  useAddress_EditMutation,
  useAddressListMutation,
  useLazyAddressDeleteQuery,
  useLazyAddressViewQuery,
  useViewPriductDetailsMutation,
  useViewProjectProductMutation,
  useViewApproveprojectMutation,
  useViewProjectDeleteMutation,
  useViewProjectProductStatusMutation,
  useGetProductPreMutation,
  useCreateorderMutation,
  useOrderListMutation,
  useGetOrderDataMutation,
  useAddressListOrderMutation,
  useAddressAddListMutation,
  useLazyAddressViewOrderQuery,
} = api;
